import styled from '@emotion/styled'
import { useMutation, useQuery } from '@tanstack/react-query'
import queryClient from 'api/query'
import Button from 'common/components/Button'
import Flex from 'common/components/Flex'
import Text from 'common/components/Text'
import Tile from 'common/components/Tile'
import { errorToast } from 'common/toasts'
import { formatCurrency } from 'common/util'
import dayjs from 'dayjs'
import { createPaymentAgreement } from 'paymentAgreements/api'
import PaymentTermsAgreement from 'paymentAgreements/components/PaymentTermsAgreement'
import { fetchPlaidIntegrations } from 'plaidIntegrations/api'
import { getAllPaymentRequests } from 'paymentRequests/api'
import { fetchPaymentTermsOptions } from 'paymentTermsOptions/api'
import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchCurrentUser } from 'user/api'

const TextboxWrapper = styled.div`
    border-radius: 10px;
    border: 1px solid var(--color-light-gray);
    overflow: hidden;
`

const Textbox = styled(Flex)`
    width: 100%;
    height: 500px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 20px;
    border-radius: 10px;
`

const TermsAcceptanceWrapper = styled(Flex)`
    width: 100%;
    & > div {
        width: 48%;
    }
`

const BoldText = styled.span`
    font-weight: 700;
`

const PaymentDetailRow = styled(Flex)`
    padding: 20px 0;
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-medium-light-gray);
    }
    & > div:last-of-type {
        max-width: 400px;
        text-align: right;
    }
`

const TermSummaryRow = styled(Flex)`
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--color-light-gray);
`

const MemoEmptyState = styled.div`
    width: 50px;
    height: 1px;
    background-color: var(--color-dark-gray);
`

const TermsAcceptance = () => {
    const navigate = useNavigate()
    const { paymentRequestId } = useParams()
    const { data: userData } = useQuery({
        queryKey: ['currentUser'],
        queryFn: fetchCurrentUser,
    })
    const { data: paymentTermsOptionsData } = useQuery({
        queryKey: ['fetchPaymentTermsOptions'],
        queryFn: fetchPaymentTermsOptions,
    })
    const selectedLoanOption = useMemo(() => {
        const selectedPaymentOptionId = queryClient.getQueryData(['selectedPaymentTermsOption'])
        return paymentTermsOptionsData?.data?.find((po) => po.id === selectedPaymentOptionId)
    }, [paymentTermsOptionsData])

    const { data: plaidIntegrationData } = useQuery({
        queryKey: ['plaidIntegrationList'],
        queryFn: fetchPlaidIntegrations,
    })

    const { data: paymentRequestData } = useQuery({
        queryKey: ['fetchPaymentRequests'],
        queryFn: getAllPaymentRequests,
    })
    const selectedPaymentRequest = useMemo(
        () => paymentRequestData?.data?.find(({ id }) => id === paymentRequestId) ?? {},
        [paymentRequestData, paymentRequestId]
    )
    const { mutate, isLoading, isSuccess } = useMutation({
        mutationKey: ['createPaymentAgreement'],
        mutationFn: createPaymentAgreement,
        onError: () =>
            errorToast('An error occurred when accepting the payment. Please try again.'),
    })
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const { payment_amount, payment_due_date, invoice_date, memo, vendor } = useMemo(
        () => selectedPaymentRequest ?? {},
        [selectedPaymentRequest]
    )
    const vendorName = useMemo(
        () => vendor?.organization?.legal_name || vendor?.organization?.name,
        [vendor]
    )
    const onSubmit = useCallback(() => {
        const paymentTermsOptionId = queryClient.getQueryData(['selectedPaymentTermsOption'])
        mutate({ paymentRequestId, paymentTermsOptionId })
    }, [mutate, paymentRequestId])

    useEffect(() => {
        if (errors.payment_confirm) {
            errorToast('Terms must be accepted to initialize payment.')
        }
    }, [navigate, selectedLoanOption, errors.payment_confirm])

    useEffect(() => {
        if (!selectedLoanOption) {
            navigate('../payment-options')
        }
        if (isSuccess) {
            queryClient.invalidateQueries({ queryKey: ['fetchPaymentRequests'] })
            navigate('../../../payments')
        }
    }, [selectedLoanOption, isSuccess, navigate])

    return (
        <Tile title="Review and Accept Payment Terms">
            <TermsAcceptanceWrapper justify="space-between">
                <Flex column>
                    <Text variant="h2">Payment Details</Text>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Invoiced Amount</Text>
                        <Text variant="p1">{formatCurrency(payment_amount)}</Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Fee to Extend with Market</Text>
                        <Text variant="p1">
                            {formatCurrency(
                                (selectedLoanOption.fee_percentage * payment_amount) / 100
                            )}
                        </Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Amount Owed</Text>
                        <Text variant="p1">
                            {formatCurrency(
                                payment_amount +
                                    (selectedLoanOption.fee_percentage * payment_amount) / 100
                            )}
                        </Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Payment Account Name:</Text>
                        <Text variant="p1">
                            {plaidIntegrationData?.data?.[0]?.meta?.institution?.name}
                        </Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Payment Account Last 4:</Text>
                        <Text variant="p1">
                            {plaidIntegrationData?.data?.[0]?.meta?.account?.mask}
                        </Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Date Started</Text>
                        <Text variant="p1">{dayjs(invoice_date).format('MM/DD/YYYY')}</Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Date Due</Text>
                        <Text variant="p1">
                            {dayjs(payment_due_date)
                                .add(selectedLoanOption?.number_of_days, 'd')
                                .format('MM/DD/YYYY')}
                        </Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Memo</Text>
                        <Text variant="p1" secondary={!memo}>
                            {memo || <MemoEmptyState />}
                        </Text>
                    </PaymentDetailRow>
                </Flex>
                <Flex gap="10" column>
                    <Text variant="h2">Extended Payment Terms Agreement</Text>
                    <TextboxWrapper>
                        <Textbox column>
                            <Text variant="h2" centered>
                                SUMMARY OF TERMS
                            </Text>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Effective Date
                                    <br />
                                    (“<BoldText>Effective Date</BoldText>”):
                                </Text>
                                <Text variant="p2">{dayjs().format('MM/DD/YYYY')}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Customer Legal Name
                                    <br />
                                    (“<BoldText>Customer</BoldText>”):
                                </Text>
                                <Text variant="p2">{userData?.data?.organization.legal_name}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Customer Jurisdiction of Formation/Incorporation:
                                </Text>
                                <Text variant="p2">
                                    {userData?.data?.organization.jurisdiction}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Customer Entity Type:</Text>
                                <Text variant="p2">{userData?.data?.organization.entity_type}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Vendor Legal Name
                                    <br />
                                    (“<BoldText>Vendor</BoldText>”):
                                </Text>
                                <Text variant="p2">{vendorName}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Invoiced Amount
                                    <br />
                                    (“<BoldText>Invoiced Amount</BoldText>”):
                                </Text>
                                <Text variant="p2">{formatCurrency(payment_amount)}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Invoice Date
                                    <br />
                                    (“<BoldText>Invoice Date</BoldText>”):
                                </Text>
                                <Text variant="p2">{dayjs(invoice_date).format('MM/DD/YYYY')}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Due Date of Payment to Vendor
                                    <br />
                                    (“<BoldText>Vendor Payment Date</BoldText>”):
                                </Text>
                                <Text variant="p2">
                                    {dayjs(payment_due_date).format('MM/DD/YYYY')}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Length of the Payment Extension
                                    <br />
                                    (“<BoldText>Payment Extension Period</BoldText>”):
                                </Text>
                                <Text variant="p2">{selectedLoanOption?.number_of_days} days</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Fee
                                    <br />
                                    (“<BoldText>Extension Fee</BoldText>”):
                                </Text>
                                <Text variant="p2">
                                    {formatCurrency(
                                        (selectedLoanOption?.fee_percentage * payment_amount) / 100
                                    )}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Extended Payment Due Date
                                    <br />
                                    (“<BoldText>Extended Payment Due Date</BoldText>”):
                                </Text>
                                <Text variant="p2">
                                    {dayjs(payment_due_date)
                                        .add(selectedLoanOption?.number_of_days, 'd')
                                        .format('MM/DD/YYYY')}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Extended Payment Amount
                                    <br />
                                    (“<BoldText>Extended Payment Amount</BoldText>”):
                                </Text>
                                <Text variant="p2">
                                    {formatCurrency(
                                        payment_amount +
                                            (selectedLoanOption.fee_percentage * payment_amount) /
                                                100
                                    )}
                                </Text>
                            </TermSummaryRow>
                            <PaymentTermsAgreement />
                        </Textbox>
                    </TextboxWrapper>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Flex m="0 0 10px 0" gap="5">
                            <Text bold>I agree to the Extended Payment Terms Agreement</Text>
                            <input
                                type="checkbox"
                                {...register('payment_confirm', { required: true })}
                            />
                        </Flex>
                        <Button size="full" type="submit" loading={isLoading}>
                            Confirm
                        </Button>
                    </form>
                </Flex>
            </TermsAcceptanceWrapper>
        </Tile>
    )
}

export default TermsAcceptance
