import { PAYMENT_AGREEMENT_STATUS } from './enums'

export const paymentRequestStatusMap = {
    'Payment Requested': {
        text: 'Requested',
        color: 'var(--color-background-blue)',
        value: 0,
    },
    'Payment Sent': {
        text: 'In Progress',
        color: 'var(--color-light-yellow)',
        value: 1,
    },
    'Payment Cleared': {
        text: 'Complete',
        color: 'var(--color-light-green)',
        value: 2,
    },
    'Payment Error': {
        text: 'Error',
        color: 'var(--color-light-red)',
        value: 3,
    },
}

export const buyerUserStatusMap = {
    'Buyer Invited': {
        text: 'Requested',
        color: 'var(--color-light-purple)',
        value: 1,
    },
    'Buyer Active': {
        text: 'Paid',
        color: 'var(--color-light-green)',
        value: 3,
    },
    'Buyer Error': {
        text: 'Error',
        color: 'var(--color-light-red)',
        value: 4,
    },
}

const initialPaymentAgreement = {
    text: 'Created',
    color: 'var(--color-light-purple)',
    value: 1,
}

export const pendingPaymentAgreement = {
    text: 'Pending',
    color: 'var(--color-background-blue)',
    value: 2,
}

const acceptedPaymentAgreement = {
    text: 'Accepted',
    color: 'var(--color-light-yellow)',
    value: 3,
}

const successfulPaymentAgreement = {
    text: 'Completed',
    color: 'var(--color-light-green)',
    value: 4,
}

const rejectedPaymentAgreement = {
    text: 'Rejected',
    color: 'var(--color-light-red)',
    value: 5,
}

const erroredPaymentAgreement = {
    text: 'Failed',
    color: 'var(--color-light-red)',
    value: 6,
}

export const paymentAgreementStatusMap = {
    [PAYMENT_AGREEMENT_STATUS.RECEIVED]: initialPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.PENDING_AUTHORIZATION]: pendingPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.PENDING_AUTHENTICATION]: pendingPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.AUTHENTICATION_REDIRECTED]: pendingPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.PENDING_MANUAL_REVIEW]: pendingPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.AUTHORIZED]: acceptedPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.CANCELLED]: rejectedPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.EXPIRED]: rejectedPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.CAPTURE_REQUESTED]: acceptedPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.SETTLED]: successfulPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.PAID]: successfulPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.RISK_DECLINED]: rejectedPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.AUTHORIZATION_FAILED]: erroredPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.CAPTURE_FAILED]: erroredPaymentAgreement,
    [PAYMENT_AGREEMENT_STATUS.FAILED_TO_PROCESS]: erroredPaymentAgreement,
}
