import Login from 'views/Login'
import Buyers from 'views/vendor/Buyers'
import Payments from 'views/buyer/Payments'
import { Navigate } from 'react-router-dom'
import personIcon from 'assets/svg/person-icon.svg'
import cardIcon from 'assets/svg/card-icon.svg'
import requestIcon from 'assets/svg/request-icon.svg'
import settingsIcon from 'assets/svg/settings-icon.svg'
import VerifyUser from 'views/buyer/onboarding/VerifyUser'
// import Onboarding from "views/buyer/onboarding/Onboarding"
// import ApplicationDenied from "views/buyer/onboarding/ApplicationDenied"
// import BusinessDetails from "views/buyer/onboarding/BusinessDetails"
// import ConnectPlaid from "views/buyer/onboarding/ConnectPlaid"
// import BuyerSignUp from "views/buyer/onboarding/BuyerSignUp"
// import PaymentOptions from "views/buyer/newPaymentRequest/PaymentOptions"
// import UnderwritingCheck from "views/buyer/onboarding/UnderwritingCheck"
// import VerifyAccount from "views/buyer/onboarding/VerifyAccount"
// import PaymentDetails from "views/buyer/PaymentDetails"
// import NewRequest from "views/buyer/newPaymentRequest/NewRequest"
import Requests from 'views/buyer/Requests'
// import PaymentRequests from "views/vendor/PaymentRequests"
// import TermsAndConditions from 'views/TermsAndConditions'
// import Settings from 'views/buyer/Settings'
// import PaymentRequestDetails from 'views/vendor/PaymentRequestDetails'
import PasswordResetRequest from 'views/PasswordResetRequest'
import PasswordReset from 'views/PasswordReset'
import { TOKEN_STORAGE_KEY } from 'common/constants'
import CreateBuyer from 'views/vendor/CreateBuyer'
import BuyerDetails from 'views/vendor/BuyerDetails'
import PaymentRequests from 'views/vendor/PaymentRequests'
import Onboarding from 'views/buyer/onboarding/Onboarding'
import BuyerSignUp from 'views/buyer/onboarding/BuyerSignUp'
import BusinessDetails from 'views/buyer/onboarding/BusinessDetails'
import ConnectPlaid from 'views/buyer/onboarding/ConnectPlaid'
import UnderwritingCheck from 'views/buyer/newPaymentRequest/UnderwritingCheck'
import ApplicationDenied from 'views/buyer/newPaymentRequest/ApplicationDenied'
import Settings from 'views/buyer/Settings'
import TermsAcceptance from 'views/buyer/newPaymentRequest/TermsAcceptance'
import PaymentOptions from 'views/buyer/newPaymentRequest/PaymentOptions'
import NewRequest from 'views/buyer/newPaymentRequest/NewRequest'
import InternalError from 'views/buyer/newPaymentRequest/InternalError'

export const ROUTES = {
    buyers: {
        path: 'buyers',
        label: 'Customers',
        element: <Buyers />,
        icon: personIcon,
        showInNav: true,
    },
    login: {
        path: 'login',
        label: 'Login',
        element: <Login />,
        showInNav: false,
    },
    passwordReset: {
        path: 'password-reset/:token',
        label: 'Password Reset',
        element: <PasswordReset />,
        showInNav: false,
    },
    requestPasswordReset: {
        path: 'request-password-reset',
        label: 'Request Password Reset',
        element: <PasswordResetRequest />,
        showInNav: false,
    },
    payments: {
        path: 'payments',
        element: <Payments />,
        icon: cardIcon,
        label: 'Payments',
        showInNav: true,
    },
    paymentDetails: {
        path: 'payments/:paymentId',
        element: <Payments />,
        label: 'Payments',
        showInNav: false,
    },
    requests: {
        path: 'requests',
        element: <Requests />,
        icon: requestIcon,
        label: 'Requests',
        showInNav: true,
    },
    paymentRequests: {
        path: 'payment-requests',
        element: <PaymentRequests />,
        icon: cardIcon,
        label: 'Requests',
        showInNav: true,
    },
    // requestPayment: {
    //   path: 'buyers/:buyerId/request-payment',
    //   element: <RequestPayment />,
    //   label: 'Request Payment',
    //   showInNav: false,
    // },
    paymentRequestDetails: {
        path: 'payment-requests/:paymentRequestId',
        element: <PaymentRequests />,
        label: 'Payment Request Details',
        showInNav: false,
    },
    buyerDetails: {
        path: 'buyers/:buyerId',
        label: 'Buyer Details',
        element: <BuyerDetails />,
        showInNav: false,
    },
    buyerPaymentRequestDetails: {
        path: 'buyers/:buyerId/payment-requests/:paymentRequestId',
        element: <BuyerDetails />,
        label: 'Buyer Payment Request Details',
        showInNav: false,
    },
    createBuyer: {
        path: 'buyers/create',
        element: <CreateBuyer />,
        showInNav: false,
        label: 'Create Buyer',
    },
    verifyUser: {
        path: 'verify-user/:token',
        element: <VerifyUser />,
        label: 'Verify User',
        showInNav: false,
    },
    // termsAndConditions: {
    //   path: 'terms-and-conditions',
    //   element: <TermsAndConditions />,
    //   label: 'Terms And Conditions',
    //   showInNav: false,
    // },
    settings: {
        path: 'settings',
        element: <Settings />,
        label: 'Settings',
        icon: settingsIcon,
        showInNav: true,
    },
    onboarding: {
        path: 'onboarding',
        element: <Onboarding />,
        label: null,
        showInNav: false,
        subRoutes: [
            {
                path: 'buyer-details',
                element: <BuyerSignUp />,
                label: null,
                showInNav: false,
            },
            {
                path: 'business-details',
                element: <BusinessDetails />,
                label: null,
                showInNav: false,
            },
            {
                path: 'connect-plaid',
                element: <ConnectPlaid />,
                label: null,
                showInNav: false,
            },
        ],
    },
    newRequest: {
        path: 'new-request/:paymentRequestId',
        element: <NewRequest />,
        label: null,
        showInNav: false,
        subRoutes: [
            {
                path: 'underwriting-check',
                element: <UnderwritingCheck />,
                label: null,
                showInNav: false,
            },
            {
                path: 'review',
                element: <TermsAcceptance />,
                label: null,
                showInNav: false,
            },
            {
                path: 'payment-options',
                element: <PaymentOptions />,
                label: null,
                showInNav: false,
            },
            {
                path: 'application-denied',
                element: <ApplicationDenied />,
                label: null,
                showInNav: false,
            },
            {
                path: 'internal-error',
                element: <InternalError />,
                label: null,
                showInNav: false,
            },
            {
                path: '*',
                element: <Navigate to="payment-options" />,
                label: null,
                showInNav: false,
            },
        ],
    },
}

const vendorRoutes = [
    ROUTES.buyerDetails,
    ROUTES.buyers,
    ROUTES.paymentRequests,
    ROUTES.createBuyer,
    ROUTES.paymentRequestDetails,
    ROUTES.buyerPaymentRequestDetails,
    // ROUTES.requestPayment,
    {
        path: '*',
        element: <Navigate to="buyers" />,
        label: 'Vendor Redirect',
        showInNav: false,
    },
]

// eslint-disable-next-line no-unused-vars
const buyerRoutes = [
    ROUTES.onboarding,
    ROUTES.newRequest,
    ROUTES.payments,
    ROUTES.paymentDetails,
    ROUTES.requests,
    ROUTES.settings,
    {
        path: '*',
        element: <Navigate to="payments" />,
        label: 'Buyer Redirect',
        showInNav: false,
    },
]

const sharedRoutes = [ROUTES.passwordReset, ROUTES.verifyUser]

export const unauthenticatedRoutes = [
    ROUTES.login,
    ROUTES.verifyUser,
    ROUTES.passwordReset,
    ROUTES.requestPasswordReset,
    {
        path: '*',
        element: <Navigate to="login" />,
        label: 'Unauthenticated Redirect',
        showInNav: false,
    },
]

export const getAvailableRoutes = (organization) => {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY)
    if (!token || !organization) {
        return unauthenticatedRoutes
    }
    let availableRoutes = sharedRoutes
    if (organization?.organization_buyer) {
        availableRoutes = [...availableRoutes, ...buyerRoutes]
    }
    if (organization?.organization_vendor) {
        availableRoutes = [...availableRoutes, ...vendorRoutes]
    }
    return availableRoutes
}
