import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import Flex from 'common/components/Flex'
import SidebarModal from 'common/components/SidebarModal'
import Text from 'common/components/Text'
import { paymentAgreementStatusMap } from 'common/statusMaps'
import StatusCell from 'common/table/StatusCell'
import { formatCurrency } from 'common/util'
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import { fetchPaymentAgreements } from 'paymentAgreements/api'
import InvoiceDetailsButton from 'paymentRequests/components/InvoiceDetailsButton'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { fetchCurrentUser } from 'user/api'
import PaymentTermsAgreement from './PaymentTermsAgreement'

const PaymentDetailRow = styled(Flex)`
    padding: 20px 0;
    align-items: center;
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-light-gray);
    }
    & > div:last-of-type {
        max-width: 400px;
        text-align: right;
    }
`

const DetailValueText = styled(Text)`
    font-size: 18px;
`

const TextboxWrapper = styled.div`
    border-radius: 10px;
    border: 1px solid var(--color-light-gray);
    overflow: hidden;
`

const Textbox = styled(Flex)`
    width: 100%;
    height: 250px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 20px;
    border-radius: 10px;
`

const BoldText = styled.span`
    font-weight: 700;
`

const TermSummaryRow = styled(Flex)`
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--color-light-gray);
`

const MemoEmptyState = styled.div`
    width: 50px;
    height: 1px;
    background-color: var(--color-dark-gray);
`

const PaymentDetailsModal = ({ ...rest }) => {
    const { paymentId } = useParams()
    const { data } = useQuery({
        queryKey: ['fetchPaymentAgreements'],
        queryFn: fetchPaymentAgreements,
        enabled: false,
    })
    const { data: userData } = useQuery({
        queryKey: ['fetchCurrentUser'],
        queryFn: fetchCurrentUser,
        enabled: false,
    })
    const { street_number, street_address, street_address_2, city_town, district, postal_area } =
        useMemo(() => userData?.data?.organization ?? {}, [userData])
    const { id, vendor_name, details, payment_request, payment_request_id, attempt_status } =
        useMemo(() => data?.data?.find((pa) => pa.id === paymentId) ?? {}, [data, paymentId])
    const market_due_date = details?.payment_due_date
    const vendor_due_date = payment_request?.payment_due_date
    return (
        <SidebarModal title="Payment Details" subtitle={`Market Payment ${id}`} {...rest}>
            <Flex column p="0 50px">
                <PaymentDetailRow justify="space-between">
                    <Flex gap="10" centered>
                        <Text variant="h3">{vendor_name}</Text>
                        <StatusCell
                            value={paymentAgreementStatusMap[attempt_status || 1]?.text}
                            statusColor={paymentAgreementStatusMap[attempt_status || 1]?.color}
                        />
                    </Flex>
                    <DetailValueText>
                        {formatCurrency(details?.original_request_amount)}
                    </DetailValueText>
                </PaymentDetailRow>
                <PaymentDetailRow justify="space-between">
                    <Text variant="h3">Fee to Extend with Market</Text>
                    <DetailValueText>
                        {formatCurrency(
                            details?.interest_rate && details?.original_request_amount
                                ? (details.interest_rate * details.original_request_amount) / 100
                                : 0
                        )}
                    </DetailValueText>
                </PaymentDetailRow>
                <PaymentDetailRow justify="space-between">
                    <Text variant="h3">Amount Owed</Text>
                    <DetailValueText>{formatCurrency(details?.payment_amount)}</DetailValueText>
                </PaymentDetailRow>
                <PaymentDetailRow justify="space-between">
                    <Text variant="h3">Date Started</Text>
                    <DetailValueText>
                        {dayjs(details?.terms_accepted_date).format('MM/DD/YYYY')}
                    </DetailValueText>
                </PaymentDetailRow>
                <PaymentDetailRow justify="space-between">
                    <Text variant="h3">Date Due</Text>
                    <DetailValueText>{dayjs(market_due_date).format('MM/DD/YYYY')}</DetailValueText>
                </PaymentDetailRow>
                <PaymentDetailRow justify="space-between">
                    <Text variant="h3">Memo</Text>
                    <Text variant="p1" secondary={!details?.request_memo}>
                        {details?.request_memo || <MemoEmptyState />}
                    </Text>
                </PaymentDetailRow>
                <Flex gap="10" m="30px 0" column>
                    <TextboxWrapper>
                        <Textbox column>
                            <Text variant="h2" centered>
                                SUMMARY OF TERMS
                            </Text>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Effective Date
                                    <br />
                                    (“<BoldText>Effective Date</BoldText>”):
                                </Text>
                                <Text variant="p2">{dayjs().format('MM/DD/YYYY')}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Customer Legal Name
                                    <br />
                                    (“<BoldText>Customer</BoldText>”):
                                </Text>
                                <Text variant="p2">
                                    {userData?.data?.organization?.legal_name ??
                                        userData?.data?.organization?.name}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Customer Jurisdiction of Formation/Incorporation:
                                </Text>
                                <Text variant="p2">
                                    {userData?.data?.organization?.jurisdiction}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Customer Entity Type:</Text>
                                <Text variant="p2">
                                    {capitalize(userData?.data?.organization?.entity_type)}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Vendor Legal Name
                                    <br />
                                    (“<BoldText>Vendor</BoldText>”):
                                </Text>
                                <Text variant="p2">{vendor_name}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Invoiced Amount
                                    <br />
                                    (“<BoldText>Invoiced Amount</BoldText>”):
                                </Text>
                                <Text variant="p2">
                                    {formatCurrency(details?.original_request_amount)}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Invoice Date
                                    <br />
                                    (“<BoldText>Invoice Date</BoldText>”):
                                </Text>
                                <Text variant="p2">
                                    {dayjs(details?.request_invoice_date).format('MM/DD/YYYY')}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Due Date of Payment to Vendor
                                    <br />
                                    (“<BoldText>Vendor Payment Date</BoldText>”):
                                </Text>
                                <Text variant="p2">
                                    {dayjs(vendor_due_date).format('MM/DD/YYYY')}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Length of the Payment Extension
                                    <br />
                                    (“<BoldText>Payment Extension Period</BoldText>”):
                                </Text>
                                <Text variant="p2">{details?.number_of_days} days</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Fee
                                    <br />
                                    (“<BoldText>Extension Fee</BoldText>”):
                                </Text>
                                <Text variant="p2">
                                    {formatCurrency(
                                        details?.interest_rate && details?.original_request_amount
                                            ? (details.interest_rate *
                                                  details.original_request_amount) /
                                                  100
                                            : 0
                                    )}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Extended Payment Due Date
                                    <br />
                                    (“<BoldText>Extended Payment Due Date</BoldText>”):
                                </Text>
                                <Text variant="p2">
                                    {dayjs(market_due_date).format('MM/DD/YYYY')}
                                </Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">
                                    Extended Payment Amount
                                    <br />
                                    (“<BoldText>Extended Payment Amount</BoldText>”):
                                </Text>
                                <Text variant="p2">{formatCurrency(details?.payment_amount)}</Text>
                            </TermSummaryRow>
                            <PaymentTermsAgreement />
                        </Textbox>
                    </TextboxWrapper>
                </Flex>
                <InvoiceDetailsButton
                    invoiceDetails={{
                        billedTo:
                            userData?.data?.organization?.legal_name ??
                            userData?.data?.organization?.name,
                        billedFrom: vendor_name,
                        billedOn: dayjs(details?.request_invoice_date).format('MMMM D, YYYY'),
                        dueDate: dayjs(details?.payment_due_date).format('MMMM D, YYYY'),
                        paymentRequestId: payment_request_id,
                        extendedPaymentFee: formatCurrency(
                            details?.interest_rate && details?.original_request_amount
                                ? (details.interest_rate * details.original_request_amount) / 100
                                : 0
                        ),
                        subtotal: formatCurrency(details?.original_request_amount),
                        total: formatCurrency(details?.payment_amount),
                        memo: details?.request_memo,
                        addressLineOne: `${street_number} ${street_address} ${
                            street_address_2 ?? ''
                        }`,
                        addressLineTwo: `${city_town}, ${district} ${postal_area}`,
                    }}
                >
                    View Invoice
                </InvoiceDetailsButton>
            </Flex>
        </SidebarModal>
    )
}

export default PaymentDetailsModal
