import Button from 'common/components/Button'
import PropTypes from 'prop-types'
import { useState } from 'react'
import InvoiceDetailsModal from './InvoiceDetailsModal'

const InvoiceDetailsButton = ({ invoiceDetails, modalState, ...rest }) => {
    const [modalOpen, setModalOpen] = useState(false)
    return (
        <>
            <Button size="full" onClick={() => setModalOpen(true)} {...rest}>
                View Invoice
            </Button>
            <InvoiceDetailsModal
                invoiceDetails={invoiceDetails}
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
            />
        </>
    )
}

InvoiceDetailsButton.propTypes = {
    invoiceDetails: PropTypes.shape({
        billedTo: PropTypes.string,
        billedFrom: PropTypes.string,
        createdOn: PropTypes.string,
        billedOn: PropTypes.string,
        dueDate: PropTypes.string,
        memo: PropTypes.string,
        subtotal: PropTypes.string,
        advanceFee: PropTypes.string,
        extendedPaymentFee: PropTypes.string,
        total: PropTypes.string,
        addressLineOne: PropTypes.string,
        addressLineTwo: PropTypes.string,
        paymentRequestId: PropTypes.string,
    }),
    modalState: PropTypes.shape({
        modalOpen: PropTypes.bool,
        setModalOpen: PropTypes.func,
    }),
}

export default InvoiceDetailsButton
