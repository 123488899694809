import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import Flex from 'common/components/Flex'
import Button from 'common/components/Button'
import { useMutation } from '@tanstack/react-query'
import { downloadInvoicePDF } from 'paymentRequests/api'
import SidebarModal from 'common/components/SidebarModal'
import Text from 'common/components/Text'
import Logo from 'common/components/Logo'

const PreviewWrapper = styled(Flex)`
    height: 78%;
`

const PDFPreview = styled(Flex)`
    width: 100%;
    height: 686px;
    padding: 35px;
    border: 1px solid var(--color-black);
    border-radius: 10px;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    & * {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
            'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    & > div {
        width: 100%;
    }
    & .smallText {
        font-size: 10px;
        font-style: italic;
        line-height: 14px;
        font-weight: 400;
    }
`

const BoldText = styled.span`
    font-weight: 700;
`

const PDFTable = styled.table`
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    & > thead > tr.mainRow > *,
    & > tbody > tr.mainRow > * {
        border-bottom: 2px solid var(--color-black);
    }
    & > tbody > tr.secondaryRow > * {
        border-bottom: 1px solid var(--color-black);
    }
    & > thead > tr > *,
    & > tbody > tr > * {
        margin: 0;
        padding: 12px 20px;
    }
    & > thead > tr > th,
    & > tbody > tr > th {
        font-size: 13px;
    }
    & > thead > tr > td,
    & > tbody > tr > td {
        text-align: center;
        font-size: 10px;
    }
    & > thead > tr > th:first-of-type,
    & > tbody > tr > th:first-of-type,
    & > tbody > tr > td:first-of-type {
        text-align: left;
        padding-left: 0;
    }
    & > thead > tr > th:last-of-type,
    & > tbody > tr > th:last-of-type,
    & > tbody > tr > td:last-of-type {
        text-align: right;
        padding-right: 0;
    }
`

const InvoiceDetailsModal = ({ invoiceDetails, onRequestClose, ...rest }) => {
    const {
        billedTo,
        billedFrom,
        createdOn,
        billedOn,
        dueDate,
        paymentRequestId,
        memo,
        subtotal,
        advanceFee,
        extendedPaymentFee,
        total,
        addressLineOne,
        addressLineTwo,
    } = useMemo(() => invoiceDetails ?? {}, [invoiceDetails])
    const { isLoading, mutate } = useMutation({
        mutationKey: ['downloadInvoicePDF'],
        mutationFn: downloadInvoicePDF,
    })
    const downloadInvoice = useCallback(() => mutate(paymentRequestId), [mutate, paymentRequestId])
    return (
        <SidebarModal
            title="Invoice Preview"
            onRequestClose={onRequestClose}
            secondaryLayer
            {...rest}
        >
            <PreviewWrapper p="0 50px" m="20px 0 0" justify="space-between" column>
                <PDFPreview justify="space-between" column>
                    <Flex column>
                        <Flex justify="space-between" m={advanceFee ? '0' : '0 0 20px'}>
                            <Text variant="h1" size="28px">
                                {billedFrom}
                            </Text>
                            <Flex align="flex-end" gap="3" column>
                                {advanceFee && (
                                    <Text variant="p2" size="11px">
                                        <BoldText>Created on:</BoldText> {createdOn}
                                    </Text>
                                )}
                                <Text variant="p2" size="11px">
                                    <BoldText>Billed on:</BoldText> {billedOn}
                                </Text>
                                <Text variant="p2" size="11px">
                                    <BoldText>Due on:</BoldText> {dueDate}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex m="0 0 10px" gap="1" column>
                            <Text variant="h3" size="12px">
                                Billed to:
                            </Text>
                            <Text size="10px" lineHeight="14px">
                                {billedTo}
                            </Text>
                            <Text size="10px" lineHeight="14px">
                                {addressLineOne}
                            </Text>
                            <Text size="10px" lineHeight="14px">
                                {addressLineTwo}
                            </Text>
                        </Flex>
                        <Flex m="0 0 10px" gap="1" column>
                            <Text variant="h3" size="12px">
                                Notes:
                            </Text>
                            <Text variant="p2" size="10px" lineHeight="14px">
                                {memo || 'N/A'}
                            </Text>
                        </Flex>
                        <PDFTable>
                            <thead>
                                <tr className="mainRow">
                                    <th>Description</th>
                                    <th>QTY</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="smallText">
                                        Payment from {billedTo} to {billedFrom} for goods/services
                                    </td>
                                    <td>1</td>
                                    <td>{subtotal}</td>
                                    <td>{subtotal}</td>
                                </tr>
                                <tr className="secondaryRow">
                                    <td>
                                        <BoldText>Subtotal</BoldText>
                                    </td>
                                    <th> </th>
                                    <th> </th>
                                    <td>{subtotal}</td>
                                </tr>
                                <tr className="secondaryRow">
                                    <td>
                                        <BoldText>
                                            {advanceFee
                                                ? 'Advance Processing Fee'
                                                : 'Extended Payment Fee'}
                                        </BoldText>
                                    </td>
                                    <th> </th>
                                    <th> </th>
                                    <td>{advanceFee ? `- ${advanceFee}` : extendedPaymentFee}</td>
                                </tr>
                                <tr className="mainRow">
                                    <th>Total</th>
                                    <th> </th>
                                    <th> </th>
                                    <th>{total}</th>
                                </tr>
                            </tbody>
                        </PDFTable>
                    </Flex>
                    <Flex justify="flex-end" gap="10" centerAlign>
                        <Text size="11px">Powered by</Text>
                        <Logo width="80px" />
                    </Flex>
                </PDFPreview>
            </PreviewWrapper>
            <Flex p="0 50px" m="30px 0 0" column centered>
                <Button size="full" onClick={downloadInvoice} loading={isLoading}>
                    Download PDF
                </Button>
            </Flex>
        </SidebarModal>
    )
}

InvoiceDetailsModal.propTypes = {
    invoiceDetails: PropTypes.shape({
        billedTo: PropTypes.string,
        billedFrom: PropTypes.string,
        createdOn: PropTypes.string,
        billedOn: PropTypes.string,
        dueDate: PropTypes.string,
        memo: PropTypes.string,
        subtotal: PropTypes.string,
        advanceFee: PropTypes.string,
        extendedPaymentFee: PropTypes.string,
        total: PropTypes.string,
        addressLineOne: PropTypes.string,
        addressLineTwo: PropTypes.string,
        paymentRequestId: PropTypes.string,
    }),
    onRequestClose: PropTypes.func,
}

export default InvoiceDetailsModal
