import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Flex from './Flex'
import Card from './Card'
import Text from './Text'

const OnboardingCard = styled(Card)`
    height: ${({ tileHeight }) => tileHeight};
`

const OnboardingTileMainImage = styled.img`
    width: ${({ imageWidth }) => imageWidth};
    height: 100%;
    object-fit: cover;
`

const TileTextWrapper = styled(Flex)`
    ${({ textWidth }) => (textWidth ? `width: ${textWidth};` : `min-width: 600px;`)}
    height: fit-content;
    border-radius: 20px;
`

const OnboardingTile = ({
    children,
    title,
    subtitle,
    imageUrl,
    textWidth,
    imageWidth = '50%',
    tileHeight = '100%',
}) => {
    return (
        <OnboardingCard tileHeight={tileHeight} shadow>
            <TileTextWrapper column centered textWidth={textWidth}>
                {title && (
                    <Flex m="0 0 28px 0" gap="10" column centered>
                        <Text variant="h1">{title}</Text>
                        {subtitle && (
                            <Text secondary centered>
                                {subtitle}
                            </Text>
                        )}
                    </Flex>
                )}
                {children}
            </TileTextWrapper>
            {imageUrl && (
                <OnboardingTileMainImage
                    src={imageUrl}
                    alt="Onboarding graphic"
                    imageWidth={imageWidth}
                />
            )}
        </OnboardingCard>
    )
}

OnboardingTile.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    imageUrl: PropTypes.string,
    tileHeight: PropTypes.string,
    textWidth: PropTypes.string,
    imageWidth: PropTypes.string,
}

export default OnboardingTile
