import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import logo from 'assets/svg/logo.svg'
import logoShort from 'assets/svg/logo-short.svg'
import { UI_ROUTES } from 'routes'
import Link from './Link'

const LogoHolder = styled.div`
    ${({ hide }) =>
        hide &&
        `
  opacity: 0;
  pointer-events: none;
`}
`

const SizedLogo = styled.img`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
`

const Logo = ({ hide, link = false, short = false, width = 'auto', height = 'auto', ...rest }) =>
    link ? (
        <LogoHolder hide={hide} {...rest}>
            <Link internal to={UI_ROUTES.DISCOVER}>
                <SizedLogo
                    width={width}
                    height={height}
                    src={short ? logoShort : logo}
                    alt="Market Logo"
                />
            </Link>
        </LogoHolder>
    ) : (
        <SizedLogo {...rest} width={width} height={height} src={logo} alt="Market Logo" />
    )

Logo.propTypes = {
    link: PropTypes.bool,
    short: PropTypes.bool,
    hide: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
}

export default Logo
