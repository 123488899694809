import { useEffect } from 'react'

const useOnClickOutside = (
    ref,
    handler,
    ignoreClickOver = false,
    refOffset = 0,
    onEscape = false
) => {
    useEffect(() => {
        const clickListener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            const refPosition = ref.current ? ref.current.offsetLeft : Number.MAX_SAFE_INTEGER
            if (ignoreClickOver && refPosition - refOffset < event.clientX) {
                return
            }
            if (!ref.current || ref.current.contains(event.target)) {
                return
            }
            if (handler) handler(event)
        }

        const keypressListener = (event) => onEscape && event.key === 'Escape' && handler(event)

        document.addEventListener('mousedown', clickListener)
        document.addEventListener('touchstart', clickListener)
        document.addEventListener('keydown', keypressListener)

        return () => {
            document.removeEventListener('mousedown', clickListener)
            document.removeEventListener('touchstart', clickListener)
            document.removeEventListener('keydown', keypressListener)
        }
    }, [ref, handler, ignoreClickOver, refOffset, onEscape])
}

export default useOnClickOutside
