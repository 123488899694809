import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import Flex from 'common/components/Flex'
import SidebarModal from 'common/components/SidebarModal'
import ProgressBar from 'common/components/ProgressBar'
import Text from 'common/components/Text'
import { paymentRequestStatusMap } from 'common/statusMaps'
import { formatCurrency } from 'common/util'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import StatusCell from 'common/table/StatusCell'
import Tooltip from 'common/components/Tooltip'
import InvoiceDetailsButton from './InvoiceDetailsButton'
import PaymentDetailRow from './PaymentDetailRow'

const InvoiceButtonHolder = styled(Flex)`
    position: relative;
`

const MemoEmptyState = styled.div`
    width: 50px;
    height: 1px;
    background-color: var(--color-dark-gray);
`

const TooltipText = styled(motion.div, {
    shouldForwardProp: (prop) => prop !== 'textHeight',
})`
    position: absolute;
    background-color: var(--color-light-gray);
    padding: 7px;
    border-radius: 7px;
    width: 250px;
    left: 125px;
    top: -75px;
    text-align: center;
    font-size: 14px;
    &::after {
        position: absolute;
        bottom: -10px;
        left: 117.5px;
        content: '\\2666';
        width: 10px;
        color: var(--color-light-gray);
    }
`

const VendorRequestDetailsModal = ({ onRequestClose, ...rest }) => {
    const [hovering, setHovering] = useState(false)
    const [invoiceModalOpen, setInvoiceModalOpen] = useState(false)
    const { paymentRequestId, buyerId } = useParams()

    const { data: paymentRequestData } = useQuery({
        queryKey: ['listPaymentRequests'],
        queryFn: () => null,
        enabled: false,
    })
    const { data: buyerPaymentRequestData } = useQuery({
        queryKey: [`buyer${buyerId}PaymentRequests`],
        queryFn: () => null,
        enabled: false,
    })
    const { data, isLoading } = useQuery({
        queryKey: ['currentUser'],
        queryFn: () => null,
        enabled: false,
    })

    const {
        created_at,
        payment_amount,
        vendor_final_payment_amount,
        vendor_fee_percentage,
        invoice_date,
        payment_due_date,
        memo,
        payment_request_status,
        buyer,
    } = useMemo(() => {
        const paymentRequests = buyerId ? buyerPaymentRequestData : paymentRequestData
        return paymentRequests?.data?.find((pr) => pr.id === paymentRequestId) ?? {}
    }, [buyerId, buyerPaymentRequestData, paymentRequestData, paymentRequestId])

    const { street_number, street_address, street_address_2, city_town, district, postal_area } =
        useMemo(() => buyer?.organization ?? {}, [buyer])

    const statusValue = useMemo(() => {
        const finalValue = paymentRequestStatusMap[payment_request_status?.at(-1)?.status]?.value
        return finalValue === paymentRequestStatusMap['Payment Error'].value
            ? paymentRequestStatusMap[payment_request_status?.at(-2)?.status]?.value
            : finalValue
    }, [payment_request_status])

    const progressMapValues = useMemo(() => {
        const statusMapValues = []
        Object.keys(paymentRequestStatusMap).forEach((k) => {
            const foundKey = payment_request_status?.find(({ status }) => status === k)
            statusMapValues.push({
                text: paymentRequestStatusMap[k]?.text,
                subtext: foundKey ? dayjs(foundKey.status_date).format('MM/DD/YYYY') : null,
            })
        })
        return statusMapValues
    }, [payment_request_status])

    const statusCellValues = useMemo(
        () => paymentRequestStatusMap[payment_request_status?.at(-1)?.status],
        [payment_request_status]
    )

    return (
        <SidebarModal
            title="Payment Request Details"
            isLoading={isLoading}
            onRequestClose={onRequestClose}
            {...rest}
        >
            <Flex column p="0 50px">
                <PaymentDetailRow
                    detailKey={
                        <Flex gap="10" centerAlign justify="space-between">
                            <Text variant="h3">
                                {buyer?.organization?.legal_name ?? buyer?.organization?.name}
                            </Text>
                            <StatusCell
                                value={statusCellValues?.text}
                                statusColor={statusCellValues?.color}
                            />
                        </Flex>
                    }
                    detailValue={formatCurrency(payment_amount)}
                />
                <PaymentDetailRow
                    detailKey={
                        <Flex centered>
                            <Text variant="h3">
                                {
                                    data?.data?.organization?.organization_vendor
                                        ?.default_payment_terms_days
                                }{' '}
                                Days Advance Fee
                            </Text>
                            <Tooltip
                                tooltipText={`This is the ${vendor_fee_percentage}% fee that is paid to Market for the advance.`}
                            />
                        </Flex>
                    }
                    detailValue={formatCurrency(payment_amount - vendor_final_payment_amount)}
                />
                <PaymentDetailRow
                    detailKey="Net Amount"
                    detailValue={formatCurrency(vendor_final_payment_amount)}
                />
                <PaymentDetailRow
                    detailKey="Invoice Date"
                    detailValue={dayjs(invoice_date).format('MM/DD/YYYY')}
                />
                <PaymentDetailRow
                    detailKey="Payment Due Date"
                    detailValue={dayjs(payment_due_date).format('MM/DD/YYYY')}
                />
                <Flex gap="120" justify="space-between" p="20px 0" m="0 0 80px" centerAlign={!memo}>
                    <Text variant="h3">Memo</Text>
                    <Text variant="p2" secondary={!memo} right>
                        {memo || <MemoEmptyState />}
                    </Text>
                </Flex>
                <ProgressBar
                    title="Payment Request Status"
                    currValue={statusValue}
                    totalValue={2}
                    progressMapValues={progressMapValues}
                    hasError={statusCellValues?.text === 'Error'}
                />
                <InvoiceButtonHolder
                    m="90px 0 0"
                    onMouseEnter={() =>
                        setHovering(payment_request_status?.at(-1)?.status === 'Payment Requested')
                    }
                    onMouseLeave={() => setHovering(false)}
                    column
                    centered
                >
                    <InvoiceDetailsButton
                        invoiceDetails={{
                            billedTo: buyer?.organization?.legal_name ?? buyer?.organization?.name,
                            billedFrom:
                                data?.data?.organization?.legal_name ??
                                data?.data?.organization?.name,
                            createdOn: dayjs(created_at).format('MMMM D, YYYY'),
                            billedOn: dayjs(invoice_date).format('MMMM D, YYYY'),
                            dueDate: dayjs(payment_due_date).format('MMMM D, YYYY'),
                            memo,
                            paymentRequestId,
                            subtotal: formatCurrency(payment_amount),
                            advanceFee: formatCurrency(
                                payment_amount - vendor_final_payment_amount
                            ),
                            addressLineOne: `${street_number} ${street_address} ${
                                street_address_2 ?? ''
                            }`,
                            addressLineTwo: `${city_town}, ${district} ${postal_area}`,
                            total: formatCurrency(vendor_final_payment_amount),
                        }}
                        modalState={{
                            modalOpen: invoiceModalOpen,
                            setModalOpen: setInvoiceModalOpen,
                        }}
                        disabled={payment_request_status?.at(-1)?.status === 'Payment Requested'}
                    >
                        View Invoice
                    </InvoiceDetailsButton>
                    <AnimatePresence>
                        {hovering && (
                            <TooltipText
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3, ease: 'easeOut' }}
                            >
                                Once this Payment Request is accepted by your customer you&apos;ll
                                have an invoice available here.
                            </TooltipText>
                        )}
                    </AnimatePresence>
                </InvoiceButtonHolder>
            </Flex>
        </SidebarModal>
    )
}

VendorRequestDetailsModal.propTypes = {
    onRequestClose: PropTypes.func,
}

export default VendorRequestDetailsModal
