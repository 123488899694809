import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'
import styled from '@emotion/styled'
import { useCallback, useMemo, useRef } from 'react'
import useOnClickOutside from 'common/hooks/useOnClickOutside'
import closeIcon from 'assets/svg/close.svg'
import Text from './Text'
import Flex from './Flex'
import Loader from './Loader'

const ModalContent = styled(motion.div)`
    padding: 0;
    box-sizing: border-box;
    border: none;
    background: var(--color-white);
    box-shadow: 0px 4px 26px var(--color-black);
    position: absolute;
    top: 0px;
    left: 100%;
    right: auto;
    width: 600px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
`

const SidebarModalHeader = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border-bottom: 1px solid var(--color-light-gray);
`
const CloseIcon = styled.img`
    height: 16px;
    width: 16px;
    cursor: pointer;

    :hover {
        opacity: 0.7;
    }
`

const SidebarModal = ({
    title,
    subtitle,
    children,
    onRequestClose,
    isOpen,
    isLoading = false,
    secondaryLayer = false,
    ...rest
}) => {
    const overlayRef = useRef(null)
    const contentRef = useRef(null)

    const overlayStyling = useMemo(
        () => ({
            zIndex: 15,
            boxSizing: 'border-box',
            background: secondaryLayer ? 'transparent' : 'var(--color-black-semi-opaque)',
            width: '100%',
            height: '100%',
            position: 'fixed',
            transition: 'background .2s ease',
        }),
        [secondaryLayer]
    )
    const handleClose = useCallback(() => {
        overlayRef.current.style.background = secondaryLayer
            ? 'transparent'
            : 'var(--color-black-semi-opaque)'
        onRequestClose()
    }, [overlayRef, onRequestClose, secondaryLayer])

    useOnClickOutside(contentRef, handleClose, true, 500, true)

    return (
        <ReactModal
            name="sidebar-modal"
            style={{
                overlay: overlayStyling,
            }}
            onAfterOpen={() => {
                document.body.style.overflow = 'hidden'
                overlayRef.current.style.background = secondaryLayer
                    ? 'transparent'
                    : 'var(--color-black-semi-opaque)'
            }}
            onAfterClose={() => {
                document.body.style.overflow = 'unset'
            }}
            onRequestClose={handleClose}
            closeTimeoutMS={200}
            shouldCloseOnOverlayClick={false}
            contentElement={(_, elementChildren) => (
                <AnimatePresence>
                    {isOpen && (
                        <ModalContent
                            ref={contentRef}
                            initial={{ x: 0 }}
                            animate={{ x: -600 }}
                            transition={{ duration: 0.2, ease: 'easeIn' }}
                            exit={{ x: 0 }}
                        >
                            {elementChildren}
                        </ModalContent>
                    )}
                </AnimatePresence>
            )}
            overlayRef={(node) => {
                overlayRef.current = node
            }}
            isOpen={isOpen}
            {...rest}
        >
            <SidebarModalHeader>
                <Flex column>
                    <Text variant="h3">{title}</Text>
                    {subtitle && <Text variant="p2">{subtitle}</Text>}
                </Flex>
                <CloseIcon src={closeIcon} onClick={handleClose} />
            </SidebarModalHeader>
            {isLoading ? <Loader /> : children}
        </ReactModal>
    )
}

SidebarModal.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.node,
    onRequestClose: PropTypes.func,
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    secondaryLayer: PropTypes.bool,
}

export default SidebarModal
