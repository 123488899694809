import styled from '@emotion/styled'
import Flex from 'common/components/Flex'
import Text from 'common/components/Text'
import PropTypes from 'prop-types'

const PaymentDetailRowWrapper = styled(Flex)`
    padding: 20px 0;
    align-items: center;
    border-bottom: ${({ lastRow }) =>
        lastRow ? 'transparent' : '1px solid var(--color-light-gray)'};
    & > div:last-of-type {
        max-width: 400px;
        text-align: right;
    }
`

const PaymentDetailRow = ({ detailKey, detailValue, lastRow = false }) => (
    <PaymentDetailRowWrapper justify="space-between" lastRow={lastRow}>
        {typeof detailKey === 'string' ? <Text variant="h3">{detailKey}</Text> : detailKey}
        <Text size="18px">{detailValue}</Text>
    </PaymentDetailRowWrapper>
)

PaymentDetailRow.propTypes = {
    detailKey: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    detailValue: PropTypes.string,
    lastRow: PropTypes.bool,
}

export default PaymentDetailRow
